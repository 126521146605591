import { useSelector } from 'react-redux';

import { selectGlobalPageTheme } from '@/store/slices/global';

const useTheme = () => {
  const theme = useSelector(selectGlobalPageTheme);

  return {
    isEventTheme: !!theme && !['light', 'dark'].includes(theme),
    theme,
    isEair: theme === 'eair',
    isEsn: theme === 'esn',
    isEds: theme === 'eds',
    isEls: theme === 'els',
  };
};

export default useTheme;
