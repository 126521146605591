import { css } from '@emotion/css';
import cls from 'classnames';
import { FC, ReactNode } from 'react';
import { screens } from 'tailwind/theme';

const maxWidthReset = css`
  @media (min-width: ${screens.md}) {
    max-width: none;
  }
`;

const maxWidthStyle = css`
  max-width: 335px;
  ${maxWidthReset}
`;

const maxWidthStyleEsn = css`
  max-width: 282px;
  ${maxWidthReset}
`;

interface ErrorInfoProps {
  header?: ReactNode;
  description?: ReactNode;
  image?: JSX.Element;
  button?: JSX.Element;
  isEsn?: boolean;
  className?: string;
}

const ErrorInfo: FC<ErrorInfoProps> = ({
  header,
  description,
  image,
  button,
  isEsn = false,
  className = '',
}) => (
  <div
    className={cls(
      'flex flex-col md:flex-row-reverse md:justify-end items-center',
      className,
    )}
  >
    <div
      className={cls(
        'flex flex-col items-center md:items-start md:w-4/12 lg:w-5/12 xl:w-4/12 text-center md:text-left',
        maxWidthStyle,
      )}
    >
      {header && (
        <h1 className="mb-2 text-h4 text-pink teair:text-air-deepBlue tesn:text-esn-secondary-superLight teds:text-white tels:text-els-blue-lighter">
          {header}
        </h1>
      )}

      {description && <p className="mb-4">{description}</p>}

      {button}
    </div>

    {image && (
      <div
        className={cls(
          'md:w-6/12 mt-12 md:mt-0 md:mr-5 text-center',
          isEsn ? maxWidthStyleEsn : maxWidthStyle,
        )}
      >
        {image}
      </div>
    )}
  </div>
);

export default ErrorInfo;
