import { GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import NormalButton from '@/components/button/normal';
import CustomLink from '@/components/custom-link';
import ErrorInfo from '@/components/error-info';
import Image from '@/components/image';
import Layout from '@/components/layout';
import getCurrentEventSlug from '@/lib/get-current-event-slug';
import getPagesCommonProps from '@/lib/get-pages-common-props';
import getThemedButtonProps from '@/lib/get-themed-button-props';
import getVariablePlainText from '@/lib/get-variable-plain-text';
import useHasMounted from '@/lib/hooks/use-has-mounted';
import useTheme from '@/lib/hooks/use-theme';
import useVariables from '@/lib/hooks/use-variables';
import { isPreviewModeEnabled as checkIfPreviewModeIsEnabled } from '@/lib/preview';
import getEventSettings from '@/middleware/content-service/get-event-settings';
import { wrapper as StoreWrapper } from '@/store';
import { setPageTheme } from '@/store/slices/global';
import {
  EventPageSlug,
  PageProps,
  PageTheme,
  ThemeSettings,
} from '@/types/views/generic';

import notFoundImgDefault from '../../public/ech-new/images/404-error.svg';

type Custom404Props = Pick<PageProps, 'navigation' | 'isPreviewModeEnabled'> & {
  themeSettingsESN: ThemeSettings | null;
  themeSettingsEAIR: ThemeSettings | null;
  themeSettingsEDS: ThemeSettings | null;
  themeSettingsELS: ThemeSettings | null;
};

type ThemeData = {
  themeSettings?: ThemeSettings;
  theme: PageTheme;
};

type ThemeSettingsMap = {
  [key in EventPageSlug]: ThemeData;
};

const getThemeData = (
  themeSettingsESN: ThemeSettings | null,
  themeSettingsEAIR: ThemeSettings | null,
  themeSettingsEDS: ThemeSettings | null,
  themeSettingsELS: ThemeSettings | null,
  slug: EventPageSlug | null,
): ThemeData | Record<string, never> => {
  const themeSettingsMap: ThemeSettingsMap = {
    'star-night': {
      ...(themeSettingsESN && { themeSettings: themeSettingsESN }),
      theme: 'esn',
    },
    air: {
      ...(themeSettingsEAIR && { themeSettings: themeSettingsEAIR }),
      theme: 'eair',
    },
    'die-sprechstunde-live': {
      ...(themeSettingsEDS && { themeSettings: themeSettingsEDS }),
      theme: 'eds',
    },
    'live-session': {
      ...(themeSettingsELS && { themeSettings: themeSettingsELS }),
      theme: 'els',
    },
  };

  return slug ? themeSettingsMap[slug] : {};
};

const Custom404: FC<Custom404Props> = ({
  navigation,
  themeSettingsESN,
  themeSettingsEAIR,
  themeSettingsEDS,
  themeSettingsELS,
  isPreviewModeEnabled,
}) => {
  // Temporary workaround for styling 404, that was caused by an empty context in getStaticProps method below.
  // We can't get current url to check if we need to apply the theme. So, it was done in the component.
  const dispatch = useDispatch();
  const router = useRouter();
  const { theme: pageTheme, isEair, isEsn, isEds, isEls } = useTheme();
  const variables = useVariables();
  const hasMounted = useHasMounted();
  const path = router.asPath;
  const currentEventPageSlug = getCurrentEventSlug(path);
  const { theme, themeSettings } = getThemeData(
    themeSettingsESN,
    themeSettingsEAIR,
    themeSettingsEDS,
    themeSettingsELS,
    currentEventPageSlug,
  );

  useEffect(() => {
    if (theme) {
      dispatch(setPageTheme(theme));
    }
  }, [dispatch, theme]);

  // render only on the client to prevent hydration issues for differently themed pages
  if (!hasMounted) {
    return null;
  }

  const notFoundImg = themeSettings?.notFoundPageBg || notFoundImgDefault;
  const notFoundBackgroundImgage = themeSettings?.errorPageBackground;
  const headerText = getVariablePlainText(variables['404-header']);
  const mainText = getVariablePlainText(variables['404-text']);
  const buttonProps = getThemedButtonProps(pageTheme);

  return (
    <>
      <div id="modals" />
      <div id="tooltip" className="relative z-20" />
      <div id="portal-root" />

      <Layout
        navigation={navigation}
        themeSettings={themeSettings}
        isPreviewModeEnabled={isPreviewModeEnabled}
      >
        <div className="relative">
          {(isEair || isEsn || isEds || isEls) && (
            <div className="-z-10 absolute h-full w-full">
              {notFoundBackgroundImgage && (
                <Image
                  src={notFoundBackgroundImgage.src}
                  useIntrinsicSize={false}
                  objectFit="cover"
                />
              )}
            </div>
          )}

          <ErrorInfo
            header={headerText}
            description={mainText}
            image={<Image {...notFoundImg} useIntrinsicSize />}
            button={
              <CustomLink href={`/${currentEventPageSlug ?? ''}`}>
                <NormalButton
                  {...buttonProps}
                  text={
                    getVariablePlainText(variables['404-button']) ||
                    'Zurück zur Website'
                  }
                />
              </CustomLink>
            }
            isEsn={isEsn}
            className="content-box pt-8 md:pt-16 pb-32 md:pb-40"
          />
        </div>
      </Layout>
    </>
  );
};

export const getStaticProps: GetStaticProps<Custom404Props> =
  StoreWrapper.getStaticProps((store) => async (ctx) => {
    try {
      const [
        { navigation },
        themeSettingsESN,
        themeSettingsEAIR,
        themeSettingsEDS,
        themeSettingsELS,
      ] = await Promise.all([
        getPagesCommonProps(store),
        getEventSettings('esn'),
        getEventSettings('eair'),
        getEventSettings('eds'),
        getEventSettings('els'),
      ]);

      const props = {
        navigation,
        themeSettingsESN,
        themeSettingsEAIR,
        themeSettingsEDS,
        themeSettingsELS,
        isPreviewModeEnabled: checkIfPreviewModeIsEnabled(ctx),
      };

      return {
        props,
        revalidate: Number(process.env.PAGE_VALIDITY_PERIOD),
      };
    } catch (error) {
      console.error(error);
      // TODO (ECH-3457): handle error somehow
      throw error;
    }
  });

export default Custom404;
